<template>
  <div id="content" class="report" data-cy="report-download-page">
    <!-- Title -->
    <ReportPage>
      <div class="report-title">
        <h1 class="mb-0"><small>Sustainability Report</small><br />{{ asset.siteName }}</h1>
        <p class="font-w600 mb-5">{{ niceAddress(asset.address) }}</p>

        <p class="font-size-h3 mb-0"><strong>Period:</strong> Jan 2020 - Dec 2020</p>
        <p class="font-size-h5"><strong>Compared to:</strong> Jan 2019 - Dec 2019</p>
      </div>
    </ReportPage>

    <!-- Contents -->
    <ReportPage>
      <div class="report-contents">
        <h2>Contents</h2>
        <div class="report-contents--line">
          <span>Overview</span>
          <span>3</span>
        </div>
        <div class="report-contents--line">
          <span>Energy</span>
          <span>4</span>
        </div>
        <div class="report-contents--line">
          <span>Gas</span>
          <span>5</span>
        </div>
        <div class="report-contents--line">
          <span>Water</span>
          <span>6</span>
        </div>
        <div class="report-contents--line">
          <span>Waste</span>
          <span>7</span>
        </div>
        <div class="report-contents--line">
          <span>Emissions</span>
          <span>8</span>
        </div>
        <div class="report-contents--line">
          <span>Projects</span>
          <span>9</span>
        </div>
      </div>
    </ReportPage>

    <!-- Overview -->
    <ReportPage>
      <h2>Overview</h2>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">{{ numberWithCommas(asset.floorArea) }} m<sup>2</sup></span
          ><br />
          <span class="report-stat--label">Floor Area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ numberWithCommas(asset.gav) }}</span
          ><br />
          <span class="report-stat--label">GAV</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ accounts.length }}</span>
          <br />
          <span class="report-stat--label">Accounts</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ asset.assetType }}</span>
          <br />
          <span class="report-stat--label">Type</span>
        </div>
      </div>
      <GoogleMap v-if="asset.address && asset.address.latitude" :map-config="mapOptions" :markers="mapMarkers" />
    </ReportPage>

    <!-- Electricity -->
    <ReportPage>
      <div class="report-page--title">
        <h2>Electricity Data</h2>
        <span><i class="fa fa-bolt fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">{{ numberWithCommas(charts.electricityUsage.currentTotal) }} kWh</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ (charts.electricityUsage.currentTotal / asset.floorArea).toFixed(2) }} kWh/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage Per Area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ numberWithCommas(charts.electricityCost.currentTotal) }}</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ (charts.electricityCost.currentTotal / asset.floorArea).toFixed(2) }}/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost Per Area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: {{ numberWithCommas(charts.electricityUsage.currentTotal) }} kWh</p>
          <p class="text-muted">Previous usage: {{ numberWithCommas(charts.electricityUsage.previousTotal) }} kWh</p>
          <Chart v-if="charts.electricityUsage.data" :options="electricityUsageOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total cost: £{{ numberWithCommas(charts.electricityCost.currentTotal) }}</p>
          <p class="text-muted">Previous cost: £{{ numberWithCommas(charts.electricityCost.previousTotal) }}</p>
          <Chart v-if="charts.electricityCost.data" :options="electricityCostOptions" />
        </div>
      </div>
      <h3 class="mb-2">Accounts</h3>
      <table class="table table-borderless table-vcenter">
        <thead class="bg-primary text-white">
          <tr>
            <th colspan="2"></th>
            <th colspan="3" class="border-left">Usage</th>
            <th colspan="3" class="border-left">Cost</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts.filter(a => a.type === 'electricity')" :key="account._id">
            <th class="text-center border-right">1</th>
            <td>{{ account.name }}</td>
            <td class="border-left">{{ numberWithCommas(charts.electricityUsage.currentTotal) }} kWh</td>
            <td>{{ numberWithCommas(charts.electricityUsage.previousTotal) }} kWh</td>
            <td>{{ getPctDiff(charts.electricityUsage.currentTotal, charts.electricityUsage.previousTotal).toFixed(2) }}%</td>
            <td class="border-left">£{{ numberWithCommas(charts.electricityCost.currentTotal) }}</td>
            <td>£{{ numberWithCommas(charts.electricityCost.previousTotal) }}</td>
            <td class="border-right">{{ getPctDiff(charts.electricityCost.currentTotal, charts.electricityCost.previousTotal).toFixed(2) }}%</td>
          </tr>
        </tbody>
      </table>
    </ReportPage>

    <!-- Gas -->
    <ReportPage>
      <div class="report-page--title">
        <h2>Gas Data</h2>
        <span><i class="fa fa-burn fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">{{ numberWithCommas(charts.gasUsage.currentTotal) }} kWh</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ (charts.gasUsage.currentTotal / asset.floorArea).toFixed(2) }} kWh/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage Per Area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ numberWithCommas(charts.gasCost.currentTotal) }}</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ (charts.gasCost.currentTotal / asset.floorArea).toFixed(2) }}/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost Per Area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: {{ numberWithCommas(charts.gasUsage.currentTotal) }} kWh</p>
          <p class="text-muted">Previous usage: {{ numberWithCommas(charts.gasUsage.previousTotal) }} kWh</p>
          <Chart v-if="charts.gasUsage.data" :options="gasUsageOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total cost: £{{ numberWithCommas(charts.gasCost.currentTotal) }}</p>
          <p class="text-muted">Previous cost: £{{ numberWithCommas(charts.gasCost.previousTotal) }}</p>
          <Chart v-if="charts.gasCost.data" :options="gasCostOptions" />
        </div>
      </div>
      <h3 class="mb-2">Accounts</h3>
      <table class="table table-borderless table-vcenter">
        <thead class="bg-primary text-white">
          <tr>
            <th colspan="2"></th>
            <th colspan="3" class="border-left">Usage</th>
            <th colspan="3" class="border-left">Cost</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts.filter(a => a.type === 'gas')" :key="account._id">
            <th class="text-center border-right">1</th>
            <td>{{ account.name }}</td>
            <td class="border-left">{{ numberWithCommas(charts.gasUsage.currentTotal) }} kWh</td>
            <td>£{{ numberWithCommas(charts.gasUsage.previousTotal) }}</td>
            <td>{{ getPctDiff(charts.gasUsage.currentTotal, charts.gasUsage.previousTotal).toFixed(2) }}%</td>
            <td class="border-left">{{ numberWithCommas(charts.gasCost.currentTotal) }} kWh</td>
            <td>£{{ numberWithCommas(charts.gasCost.previousTotal) }}</td>
            <td class="border-right">{{ getPctDiff(charts.gasCost.currentTotal, charts.gasCost.previousTotal).toFixed(2) }}%</td>
          </tr>
        </tbody>
      </table>
    </ReportPage>

    <!-- Water -->
    <ReportPage>
      <div class="report-page--title">
        <h2>Water Data</h2>
        <span><i class="fa fa-tint fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">{{ numberWithCommas(charts.waterUsage.currentTotal) }} kWh</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ (charts.waterUsage.currentTotal / asset.floorArea).toFixed(2) }} kWh/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) < 0,
                'text-danger': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) > 0,
                'text-muted': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Usage Per Area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ numberWithCommas(charts.waterCost.currentTotal) }}</span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£{{ (charts.waterCost.currentTotal / asset.floorArea).toFixed(2) }}/m<sup>2</sup></span>
          <span class="report-stat--change"
            ><small
              :class="{
                'text-success': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) < 0,
                'text-danger': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) > 0,
                'text-muted': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) === 0
              }"
              ><i
                class="fa"
                :class="{
                  'fa-long-arrow-up': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) > 0,
                  'fa-long-arrow-down': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) < 0,
                  'fa-minus': getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal) === 0
                }"
              ></i>
              {{ getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal, true).toFixed(2) }}%</small
            > </span
          ><br />
          <span class="report-stat--label">Cost Per Area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: {{ numberWithCommas(charts.waterUsage.currentTotal) }} m<sup>3</sup></p>
          <p class="text-muted">Previous usage: {{ numberWithCommas(charts.waterUsage.previousTotal) }} m<sup>3</sup></p>
          <Chart v-if="charts.waterUsage.data" :options="waterUsageOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total cost: £{{ numberWithCommas(charts.waterCost.currentTotal) }}</p>
          <p class="text-muted">Previous cost: £{{ numberWithCommas(charts.waterCost.previousTotal) }}</p>
          <Chart v-if="charts.waterCost.data" :options="waterCostOptions" />
        </div>
      </div>
      <h3 class="mb-2">Accounts</h3>
      <table class="table table-borderless table-vcenter">
        <thead class="bg-primary text-white">
          <tr>
            <th colspan="2"></th>
            <th colspan="3" class="border-left">Usage</th>
            <th colspan="3" class="border-left">Cost</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
            <th class="border-left">Current</th>
            <th>Previous</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts.filter(a => a.type === 'water')" :key="account._id">
            <th class="text-center border-right">1</th>
            <td>{{ account.name }}</td>
            <td class="border-left">{{ numberWithCommas(charts.waterUsage.currentTotal) }} m<sup>3</sup></td>
            <td>£{{ numberWithCommas(charts.waterUsage.previousTotal) }}</td>
            <td>{{ getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal).toFixed(2) }}%</td>
            <td class="border-left">{{ numberWithCommas(charts.waterCost.currentTotal) }} m<sup>3</sup></td>
            <td>£{{ numberWithCommas(charts.waterCost.previousTotal) }}</td>
            <td class="border-right">{{ getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal).toFixed(2) }}%</td>
          </tr>
        </tbody>
      </table>
    </ReportPage>

    <!-- Emissions -->
    <ReportPage>
      <div class="report-page--title">
        <h2>Emissions</h2>
        <span><i class="fad fa-smog fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e</span>
          <span class="report-stat--change"
            ><small class="text-danger"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e/m<sub>2</sub></span
          ><span class="report-stat--change">
            <small class="text-danger"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e/m<sub>2</sub></span
          ><span class="report-stat--change">
            <small class="text-danger"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-12">
          <Chart :options="energyChartOptions" />
        </div>
        <h3 class="mb-2">Accounts</h3>
        <table class="table table-borderless table-vcenter">
          <thead class="bg-primary text-white">
            <tr>
              <th colspan="2"></th>
              <th colspan="3" class="border-left">Usage</th>
              <th colspan="3" class="border-left">Cost</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th class="border-left">Current</th>
              <th>Previous</th>
              <th>%</th>
              <th class="border-left">Current</th>
              <th>Previous</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in accounts.filter(a => a.type === 'water')" :key="account._id">
              <th class="text-center border-right">1</th>
              <td>{{ account.name }}</td>
              <td class="border-left">{{ numberWithCommas(charts.waterUsage.currentTotal) }} kWh</td>
              <td>£{{ numberWithCommas(charts.waterUsage.previousTotal) }}</td>
              <td>{{ getPctDiff(charts.waterUsage.currentTotal, charts.waterUsage.previousTotal).toFixed(2) }}%</td>
              <td class="border-left">{{ numberWithCommas(charts.waterCost.currentTotal) }} kWh</td>
              <td>£{{ numberWithCommas(charts.waterCost.previousTotal) }}</td>
              <td class="border-right">{{ getPctDiff(charts.waterCost.currentTotal, charts.waterCost.previousTotal).toFixed(2) }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ReportPage>

    <!-- Projects -->
    <ReportPage>
      <div class="report-page--title">
        <h2>Projects</h2>
        <span><i class="fad fa-tasks fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">{{ projects.filter(p => p.status === 'pending').length }}</span>
          <span class="report-stat--change"
            ><small class="text-muted"><i class="fa fa-minus"></i> 0%</small> </span
          ><br />
          <span class="report-stat--label">Pending</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ projects.filter(p => p.status === 'active').length }}</span
          ><span class="report-stat--change">
            <small class="text-muted"><i class="fa fa-minus"></i> 0%</small> </span
          ><br />
          <span class="report-stat--label">In Progress</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ projects.filter(p => p.status === 'completed').length }}</span
          ><span class="report-stat--change">
            <small class="text-muted"><i class="fa fa-minus"></i> 0%</small> </span
          ><br />
          <span class="report-stat--label">Completed On-time</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">{{ projects.filter(p => p.status === 'completed').length }}</span
          ><span class="report-stat--change">
            <small class="text-muted"><i class="fa fa-minus"></i> 0%</small> </span
          ><br />
          <span class="report-stat--label">Completed Late</span>
        </div>
      </div>
      <h3 class="mb-2">Summary</h3>
      <p>Summary of all projects.</p>
      <div class="row">
        <div class="col-12">
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>Project Name</th>
                <th>Account</th>
                <th>Status</th>
                <th>Tasks Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in projects" :key="project._id">
                <td>{{ project.name }}</td>
                <td>{{ getProjectAccountName(project) }}</td>
                <td class="text-capitalize">{{ project.status }}</td>
                <td>
                  {{ project.tasks.filter(t => t.status === 'completed').length }}/{{ project.tasks.length }} ({{
                    ((project.tasks.filter(t => t.status === 'completed').length * 100) / project.tasks.length).toFixed(0)
                  }}%)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import moment from 'moment';
import { mapActions } from 'vuex';
import 'highcharts/css/highcharts.css';
import GoogleMap from '@/components/GoogleMap';
import { numberWithCommas, niceAddress } from '../../../lib/helpers';

import ReportPage from '@/components/ReportPage';

const apiFormat = 'DD/MM/YYYY';
const longFormat = 'DD MMM YYYY';

const baseChartOptions = {
  credits: {
    enabled: false
  }
};

export default {
  name: 'Report',
  components: {
    GoogleMap,
    Chart,
    ReportPage
  },
  props: {
    startDate: {
      type: Date,
      required: true
    },
    endDate: {
      type: Date,
      required: true
    },
    startDateComparison: {
      type: Date,
      required: true
    },
    endDateComparison: {
      type: Date,
      required: true
    },
    asset: {
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      energyChartOptions: {
        ...baseChartOptions,
        chart: {
          type: 'line',
          styledMode: true,
          height: '300px'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Cost (£)'
          },
          min: 0,
          plotlines: {
            width: 0
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
              radius: 3
            }
          },
          line: {
            marker: {
              symbol: 'circle'
            }
          }
        }
      },
      emissionsChart: {
        ...baseChartOptions,
        chart: {
          type: 'column'
        },
        title: {
          text: `Emissions`
        },
        xAxis: {
          categories: ['Scope 1', 'Scope 2', 'Scope 3']
        },
        yAxis: {
          title: {
            text: 'Usage (tons CO2e)'
          },
          min: 0
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        }
      },
      pieChartOptions: {
        ...baseChartOptions,
        chart: {
          type: 'pie',
          styledMode: true
        },
        title: {
          text: ''
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.percentage:.1f}%)',
              connectorShape: 'straight',
              distance: 40,
              padding: 5
            }
          }
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [
              {
                name: 'Education',
                y: 70
              },
              {
                name: 'Retail',
                y: 10
              },
              {
                name: 'Office',
                y: 8
              },
              {
                name: 'Industrial',
                y: 6
              },
              {
                name: 'Retail',
                y: 4
              },
              {
                name: 'Hotel',
                y: 2
              }
            ]
          }
        ]
      },
      charts: {
        electricityUsage: { data: [], currentTotal: 0, previousTotal: 0 },
        gasUsage: { data: [], currentTotal: 0, previousTotal: 0 },
        waterUsage: { data: [], currentTotal: 0, previousTotal: 0 },
        electricityCost: { data: [], currentTotal: 0, previousTotal: 0 },
        gasCost: { data: [], currentTotal: 0, previousTotal: 0 },
        waterCost: { data: [], currentTotal: 0, previousTotal: 0 },
        emissions: { data: [], currentTotal: 0, previousTotal: 0 }
      }
    };
  },
  computed: {
    electricityUsageOptions() {
      return { ...this.energyChartOptions, series: this.charts.electricityUsage.data };
    },
    gasUsageOptions() {
      return { ...this.energyChartOptions, series: this.charts.gasUsage.data };
    },
    electricityCostOptions() {
      return { ...this.energyChartOptions, series: this.charts.electricityCost.data };
    },
    gasCostOptions() {
      return { ...this.energyChartOptions, series: this.charts.gasCost.data };
    },
    waterUsageOptions() {
      return { ...this.energyChartOptions, series: this.charts.waterUsage.data };
    },
    waterCostOptions() {
      return { ...this.energyChartOptions, series: this.charts.waterCost.data };
    },
    currentAccounts() {
      return this.accounts.filter(account => new Date(account.createdAt));
    },
    previousAccounts() {
      return this.accounts.filter(account => new Date(account.createdAt) < this.startDate && new Date(account.createdAt) > this.startDateComparison);
    },
    mapOptions() {
      return {
        center: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        zoom: 24,
        mapId: '490d6a9e0a1ed398',
        disableDefaultUI: true
      };
    },
    mapMarkers() {
      return [
        {
          pos: {
            lat: parseFloat(this.asset.address.latitude),
            lng: parseFloat(this.asset.address.longitude)
          },
          title: this.asset.siteName
        }
      ];
    }
  },
  async mounted() {
    await this.getAssetAnalytics('electricityUsage', 'asset-energy', 'totalUnits', 'electricity');
    await this.getAssetAnalytics('electricityCost', 'asset-energy', 'totalCost', 'electricity');
    await this.getAssetAnalytics('gasUsage', 'asset-energy', 'totalUnits', 'gas');
    await this.getAssetAnalytics('gasCost', 'asset-energy', 'totalCost', 'gas');
    await this.getAssetAnalytics('waterUsage', 'asset-energy', 'totalUnits', 'water');
    await this.getAssetAnalytics('waterCost', 'asset-energy', 'totalCost', 'water');
    await this.getAssetAnalytics('emissions', 'ghg', null, null, null);
  },
  methods: {
    ...mapActions({
      fetchAssetAnalytics: 'asset/getAssetAnalytics'
    }),
    numberWithCommas,
    niceAddress,
    getProjectAccountName(project) {
      const account = this.accounts.find(a => a._id === project.accountId);

      return account ? account.name : '';
    },
    async getAssetAnalytics(key, type, field, accountType, comparePeriod = 'year') {
      const options = {
        type,
        startDate: moment(this.startDate, longFormat).format(apiFormat),
        endDate: moment(this.endDate, longFormat).format(apiFormat)
      };

      if (field) options.field = field;
      if (accountType) options.accountType = accountType;
      if (comparePeriod) options.comparePeriod = comparePeriod;

      this.charts[key].data = await this.fetchAssetAnalytics({
        id: this.$route.params.id,
        options
      });

      if (this.charts[key].data.length > 1) {
        this.charts[key].currentTotal = this.charts[key].data[0].data.reduce((sum, row) => sum + row.y, 0).toFixed(2);
        this.charts[key].previousTotal = this.charts[key].data[1].data.reduce((sum, row) => sum + row.y, 0).toFixed(2);
      }
    },
    getPctDiff(a, b, abs = false) {
      let diff = ((a - b) * 100) / b;

      diff = abs ? Math.abs(diff) : diff;

      if (!isFinite(diff)) {
        return 0;
      }
    }
  }
};
</script>
<style lang="scss">
$primary: #3c5c61;
$secondary: #4d9575;
$faded: #82979a;

.report {
  .fad {
    --fa-primary-color: #{$primary};
    --fa-secondary-color: #{$secondary};
  }

  &-page {
    display: flex;
    flex-direction: column;

    height: 1410px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $primary !important;

      small {
        color: $faded !important;
      }
    }

    p {
      color: $primary !important;

      small {
        color: $faded !important;
      }
    }

    background-color: #fff;
    padding: 64px;
    margin-bottom: 25px;

    &--title {
      color: $primary;
      display: flex;
      justify-content: space-between;
      align-items: middle;
    }
  }

  &-logo {
    text-align: right;
  }

  &-header {
    margin-bottom: 64px;
  }

  &-body {
    flex-grow: 1;
  }

  &-footer {
    text-align: center;
    margin-top: 64px;
  }

  &-title {
    margin-top: 256px;
  }

  &-contents {
    margin: 0 32px 0 32px;

    &--line {
      color: $secondary !important;
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }
  }

  &-stats {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 32px 0 64px 0;
  }

  &-stat {
    font-size: 20px;

    &--value {
      font-size: 24px;
    }

    &--change {
      font-size: 20px;
      margin-left: 6px;
    }

    &--label {
      color: $faded;
    }
  }
}

.highcharts-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-weight: bold;
}

.highcharts-data-label text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  font-size: 16px;
  fill: $primary;
  font-weight: 400;

  .highcharts-strong {
    font-weight: 400;
  }
}

.highcharts-data-label-connector {
  fill: none !important;
}

.highcharts-color-0 {
  fill: $primary;
  stroke: $primary;
}

.highcharts-color-1 {
  fill: #3a7973;
  stroke: #3a7973;
}

.highcharts-color-2 {
  fill: $secondary;
  stroke: $secondary;
}

.highcharts-color-3 {
  fill: #79af6c;
  stroke: #79af6c;
}

.highcharts-color-4 {
  fill: #b6c35f;
  stroke: #b6c35f;
}

.highcharts-color-5 {
  fill: #ffd15e;
  stroke: #ffd15e;
}
.highcharts-axis-labels {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-size: 12px;
}

.highcharts-axis .highcharts-axis-title,
.highcharts-legend-item > text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-size: 14px;
  font-weight: 600;
}

.highcharts-axis .highcharts-axis-line {
  stroke-width: 2px;
  stroke: $faded;
}
</style>
