var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report",
    attrs: {
      "id": "content",
      "data-cy": "report-download-page"
    }
  }, [_c('ReportPage', [_c('div', {
    staticClass: "report-title"
  }, [_c('h1', {
    staticClass: "mb-0"
  }, [_c('small', [_vm._v("Sustainability Report")]), _c('br'), _vm._v(_vm._s(_vm.asset.siteName))]), _c('p', {
    staticClass: "font-w600 mb-5"
  }, [_vm._v(_vm._s(_vm.niceAddress(_vm.asset.address)))]), _c('p', {
    staticClass: "font-size-h3 mb-0"
  }, [_c('strong', [_vm._v("Period:")]), _vm._v(" Jan 2020 - Dec 2020")]), _c('p', {
    staticClass: "font-size-h5"
  }, [_c('strong', [_vm._v("Compared to:")]), _vm._v(" Jan 2019 - Dec 2019")])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-contents"
  }, [_c('h2', [_vm._v("Contents")]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Overview")]), _c('span', [_vm._v("3")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Energy")]), _c('span', [_vm._v("4")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Gas")]), _c('span', [_vm._v("5")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Water")]), _c('span', [_vm._v("6")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Waste")]), _c('span', [_vm._v("7")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Emissions")]), _c('span', [_vm._v("8")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Projects")]), _c('span', [_vm._v("9")])])])]), _c('ReportPage', [_c('h2', [_vm._v("Overview")]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.asset.floorArea)) + " m"), _c('sup', [_vm._v("2")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Floor Area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.asset.gav)))]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("GAV")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.accounts.length))]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Accounts")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.asset.assetType))]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Type")])])]), _vm.asset.address && _vm.asset.address.latitude ? _c('GoogleMap', {
    attrs: {
      "map-config": _vm.mapOptions,
      "markers": _vm.mapMarkers
    }
  }) : _vm._e()], 1), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Electricity Data")]), _c('span', [_c('i', {
    staticClass: "fa fa-bolt fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.electricityUsage.currentTotal)) + " kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s((_vm.charts.electricityUsage.currentTotal / _vm.asset.floorArea).toFixed(2)) + " kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage Per Area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.electricityCost.currentTotal)))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s((_vm.charts.electricityCost.currentTotal / _vm.asset.floorArea).toFixed(2)) + "/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost Per Area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.electricityUsage.currentTotal)) + " kWh")]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.electricityUsage.previousTotal)) + " kWh")]), _vm.charts.electricityUsage.data ? _c('Chart', {
    attrs: {
      "options": _vm.electricityUsageOptions
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.electricityCost.currentTotal)))]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.electricityCost.previousTotal)))]), _vm.charts.electricityCost.data ? _c('Chart', {
    attrs: {
      "options": _vm.electricityCostOptions
    }
  }) : _vm._e()], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Accounts")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Usage")]), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")])]), _c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")])])]), _c('tbody', _vm._l(_vm.accounts.filter(function (a) {
    return a.type === 'electricity';
  }), function (account) {
    return _c('tr', {
      key: account._id
    }, [_c('th', {
      staticClass: "text-center border-right"
    }, [_vm._v("1")]), _c('td', [_vm._v(_vm._s(account.name))]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.electricityUsage.currentTotal)) + " kWh")]), _c('td', [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.electricityUsage.previousTotal)) + " kWh")]), _c('td', [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.electricityUsage.currentTotal, _vm.charts.electricityUsage.previousTotal).toFixed(2)) + "%")]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.electricityCost.currentTotal)))]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.electricityCost.previousTotal)))]), _c('td', {
      staticClass: "border-right"
    }, [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.electricityCost.currentTotal, _vm.charts.electricityCost.previousTotal).toFixed(2)) + "%")])]);
  }), 0)])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Gas Data")]), _c('span', [_c('i', {
    staticClass: "fa fa-burn fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.gasUsage.currentTotal)) + " kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s((_vm.charts.gasUsage.currentTotal / _vm.asset.floorArea).toFixed(2)) + " kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage Per Area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.gasCost.currentTotal)))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s((_vm.charts.gasCost.currentTotal / _vm.asset.floorArea).toFixed(2)) + "/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost Per Area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.gasUsage.currentTotal)) + " kWh")]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.gasUsage.previousTotal)) + " kWh")]), _vm.charts.gasUsage.data ? _c('Chart', {
    attrs: {
      "options": _vm.gasUsageOptions
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.gasCost.currentTotal)))]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.gasCost.previousTotal)))]), _vm.charts.gasCost.data ? _c('Chart', {
    attrs: {
      "options": _vm.gasCostOptions
    }
  }) : _vm._e()], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Accounts")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Usage")]), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")])]), _c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")])])]), _c('tbody', _vm._l(_vm.accounts.filter(function (a) {
    return a.type === 'gas';
  }), function (account) {
    return _c('tr', {
      key: account._id
    }, [_c('th', {
      staticClass: "text-center border-right"
    }, [_vm._v("1")]), _c('td', [_vm._v(_vm._s(account.name))]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.gasUsage.currentTotal)) + " kWh")]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.gasUsage.previousTotal)))]), _c('td', [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.gasUsage.currentTotal, _vm.charts.gasUsage.previousTotal).toFixed(2)) + "%")]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.gasCost.currentTotal)) + " kWh")]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.gasCost.previousTotal)))]), _c('td', {
      staticClass: "border-right"
    }, [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.gasCost.currentTotal, _vm.charts.gasCost.previousTotal).toFixed(2)) + "%")])]);
  }), 0)])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Water Data")]), _c('span', [_c('i', {
    staticClass: "fa fa-tint fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.currentTotal)) + " kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s((_vm.charts.waterUsage.currentTotal / _vm.asset.floorArea).toFixed(2)) + " kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage Per Area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.waterCost.currentTotal)))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s((_vm.charts.waterCost.currentTotal / _vm.asset.floorArea).toFixed(2)) + "/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) < 0,
      'text-danger': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) > 0,
      'text-muted': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) < 0,
      'fa-minus': _vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal, true).toFixed(2)) + "%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost Per Area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.currentTotal)) + " m"), _c('sup', [_vm._v("3")])]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous usage: " + _vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.previousTotal)) + " m"), _c('sup', [_vm._v("3")])]), _vm.charts.waterUsage.data ? _c('Chart', {
    attrs: {
      "options": _vm.waterUsageOptions
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.waterCost.currentTotal)))]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Previous cost: £" + _vm._s(_vm.numberWithCommas(_vm.charts.waterCost.previousTotal)))]), _vm.charts.waterCost.data ? _c('Chart', {
    attrs: {
      "options": _vm.waterCostOptions
    }
  }) : _vm._e()], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Accounts")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Usage")]), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")])]), _c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")])])]), _c('tbody', _vm._l(_vm.accounts.filter(function (a) {
    return a.type === 'water';
  }), function (account) {
    return _c('tr', {
      key: account._id
    }, [_c('th', {
      staticClass: "text-center border-right"
    }, [_vm._v("1")]), _c('td', [_vm._v(_vm._s(account.name))]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.currentTotal)) + " m"), _c('sup', [_vm._v("3")])]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.previousTotal)))]), _c('td', [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal).toFixed(2)) + "%")]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.waterCost.currentTotal)) + " m"), _c('sup', [_vm._v("3")])]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.waterCost.previousTotal)))]), _c('td', {
      staticClass: "border-right"
    }, [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal).toFixed(2)) + "%")])]);
  }), 0)])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Emissions")]), _c('span', [_c('i', {
    staticClass: "fad fa-smog fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43.32 MTCO2e")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43.32 MTCO2e/m"), _c('sub', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43.32 MTCO2e/m"), _c('sub', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Accounts")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Usage")]), _c('th', {
    staticClass: "border-left",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")])]), _c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")]), _c('th', {
    staticClass: "border-left"
  }, [_vm._v("Current")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("%")])])]), _c('tbody', _vm._l(_vm.accounts.filter(function (a) {
    return a.type === 'water';
  }), function (account) {
    return _c('tr', {
      key: account._id
    }, [_c('th', {
      staticClass: "text-center border-right"
    }, [_vm._v("1")]), _c('td', [_vm._v(_vm._s(account.name))]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.currentTotal)) + " kWh")]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.waterUsage.previousTotal)))]), _c('td', [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.waterUsage.currentTotal, _vm.charts.waterUsage.previousTotal).toFixed(2)) + "%")]), _c('td', {
      staticClass: "border-left"
    }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.charts.waterCost.currentTotal)) + " kWh")]), _c('td', [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.charts.waterCost.previousTotal)))]), _c('td', {
      staticClass: "border-right"
    }, [_vm._v(_vm._s(_vm.getPctDiff(_vm.charts.waterCost.currentTotal, _vm.charts.waterCost.previousTotal).toFixed(2)) + "%")])]);
  }), 0)])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Projects")]), _c('span', [_c('i', {
    staticClass: "fad fa-tasks fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.projects.filter(function (p) {
    return p.status === 'pending';
  }).length))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" 0%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Pending")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.projects.filter(function (p) {
    return p.status === 'active';
  }).length))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" 0%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("In Progress")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.projects.filter(function (p) {
    return p.status === 'completed';
  }).length))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" 0%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Completed On-time")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.projects.filter(function (p) {
    return p.status === 'completed';
  }).length))]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" 0%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Completed Late")])])]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Summary")]), _c('p', [_vm._v("Summary of all projects.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("Project Name")]), _c('th', [_vm._v("Account")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Tasks Completed")])])]), _c('tbody', _vm._l(_vm.projects, function (project) {
    return _c('tr', {
      key: project._id
    }, [_c('td', [_vm._v(_vm._s(project.name))]), _c('td', [_vm._v(_vm._s(_vm.getProjectAccountName(project)))]), _c('td', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(project.status))]), _c('td', [_vm._v(" " + _vm._s(project.tasks.filter(function (t) {
      return t.status === 'completed';
    }).length) + "/" + _vm._s(project.tasks.length) + " (" + _vm._s((project.tasks.filter(function (t) {
      return t.status === 'completed';
    }).length * 100 / project.tasks.length).toFixed(0)) + "%) ")])]);
  }), 0)])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }