<template>
  <div class="content">
    <Spinner v-if="loading"></Spinner>
    <component
      :is="$route.params.reportType"
      v-else
      :asset="asset"
      :accounts="accounts"
      :start-date="startDate"
      :end-date="endDate"
      :projects="projects"
      :start-date-comparison="startDateComparison"
      :end-date-comparison="endDateComparison"
    ></component>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Spinner from '@/components/Spinner';
import ReportPortfolio from './ReportPortfolio';
import ReportAsset from './ReportAsset';
import ReportPortfolioTable from './ReportPortfolioTable';
import ReportDQTable from './ReportDQTable';

export default {
  name: 'AssetReportView',
  components: {
    Spinner,
    portfolio: ReportPortfolio,
    asset: ReportAsset,
    'portfolio-table': ReportPortfolioTable,
    'dq-table': ReportDQTable
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      suppliers: 'supplier/suppliers',
      projects: 'asset/projects'
    }),
    startDate() {
      return moment().subtract(1, 'year').startOf('year').toDate();
    },
    endDate() {
      return moment().subtract(1, 'year').endOf('year').toDate();
    },
    startDateComparison() {
      return moment().subtract(2, 'year').startOf('year').toDate();
    },
    endDateComparison() {
      return moment().subtract(2, 'year').endOf('year').toDate();
    }
  },
  async mounted() {
    this.loading = true;

    await this.getAccounts({ id: this.$route.params.id });
    await this.listProjects({ params: { assetId: this.$route.params.id } });
    await this.listDistributors({ data: { params: { countryCode: this.asset.address.countryCode } } });

    if (this.invoiceKeys.length === 0) {
      await this.getInvoiceKeys();
    }

    if (this.suppliers.length === 0) {
      await this.listSuppliers();
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      downloadReport: 'report/download',
      getAccounts: 'asset/accounts',
      listDistributors: 'supplier/listDistributors',
      getInvoiceKeys: 'invoiceTemplate/getInvoiceKeys',
      listSuppliers: 'supplier/list',
      listProjects: 'asset/listProjects'
    })
  }
};
</script>
